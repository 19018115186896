import * as React from "react";
import { graphql, PageProps } from "gatsby";
import MainLayout from "../../layouts/main";
import { YoastSEOType } from "../../assets/Types";
import SEO from "gatsby-plugin-wpgraphql-seo";
import HeroGatedVideoContainer from "../../containers/HeroGatedVideoContainer/HeroGatedVideoContainer";
import GatedVideoCard from "../../components/GatedVideoCard/GatedVideoCard";
import styles from "./ClassesStyles.module.scss";
import { deIndexSeo } from "../../utils/deIndexSeo";

type BlankProps = {
  wpPage: {
    seo: YoastSEOType;
    title: string;
    content: string;
    template: {
      classesVideoP: Queries.WpClassesTemplate_Classesvideop;
    };
  };
};

const ClassesTemplate: React.FC<PageProps<BlankProps>> = ({ data }) => {
  const { wpPage } = data;

  const { template } = wpPage;

  const { classesVideoP } = template;

  const {
    gatedVideosHeroBadge,
    gatedVideosHeroFirstButton,
    gatedVideosHeroImage,
    gatedVideosHeroSecondButton,
    gatedVideosHeroSubcopy,
    gatedVideosHeroTitle,
    gatedVideosHeroWistiaId,
    gatedVideosSection,
    gatedVideoHeroLength,
    gatedVideosHeroDescription,
  } = classesVideoP;

  const heroData = {
    gatedVideosHeroBadge: gatedVideosHeroBadge,
    gatedVideosHeroTitle: gatedVideosHeroTitle,
    gatedVideosHeroDescription,
    gatedVideosHeroSubcopy: gatedVideosHeroSubcopy,
    gatedVideosHeroFirstButton: gatedVideosHeroFirstButton,
    gatedVideosHeroSecondButton: gatedVideosHeroSecondButton,
    gatedVideosHeroWistiaId: gatedVideosHeroWistiaId,
    gatedVideoHeroLength: gatedVideoHeroLength,
    videoData: {
      videoId: gatedVideosHeroWistiaId,
      videoBackgroundImage: {
        image: gatedVideosHeroImage?.gatsbyImage,
        altText: gatedVideosHeroImage?.altText,
      },
    },
  };

  return (
    <MainLayout>
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}
      <HeroGatedVideoContainer
        heroData={heroData}
        locked={false}
        shouldShowScrollMore={false}
        classHero={styles.containerHeroClasses}
        classHeroCopy={styles.heroCopy}
        classHeroGroup={styles.heroCopyButtonGroup}
      />
      <section className={`${styles.containerClasses} full-width`}>
        <div>
          {gatedVideosSection?.map((section, index) => (
            <>
              <h2 className={styles.titleSection}>
                {section?.videoSectionTitle}
              </h2>
              <div className={styles.test}>
                {section?.videosSection?.map((item, key) => {
                  return (
                    <GatedVideoCard
                      key={index}
                      video={item}
                      signupSuccess={true}
                      videoDuration={item.videoLength}
                      gatedClass={styles.gatedVideoCard}
                      thumbNailClass={styles.gatedVideoThumbnail}
                    />
                  );
                })}
              </div>
            </>
          ))}
        </div>
      </section>
    </MainLayout>
  );
};

export default ClassesTemplate;

export const ClassesQuery = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      ...YoastSEOFragment
      template {
        ... on WpClassesTemplate {
          classesVideoP {
            gatedVideosHeroBadge
            gatedVideosHeroFirstButton
            gatedVideosHeroImage {
              altText
              gatsbyImage(layout: FULL_WIDTH, width: 1200)
            }
            gatedVideosHeroImageAltText
            gatedVideosHeroSecondButton
            gatedVideosHeroSubcopy
            gatedVideosHeroDescription
            gatedVideosHeroTitle
            gatedVideosHeroWistiaId
            gatedVideoHeroLength
            gatedVideosSection {
              videoSectionTitle
              videosSection {
                videoBackground {
                  altText
                  gatsbyImage(layout: FULL_WIDTH, width: 1200)
                }
                videoLength
                videoTitle
                videoWistiaId
              }
            }
          }
        }
      }
    }
  }
`;
